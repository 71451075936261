import { lazy } from "react";


const Home = lazy(() => import("./Home"));
const Information = lazy(() => import("./Information"));
const About = lazy(() => import("./About"));
const Plant = lazy(() => import("./plant"));
const TouristNotice = lazy(() => import("./TouristNotice"));
const ContentPage = lazy(() => import("./ContentPage"));
const ContentList = lazy(() => import("./ContentList"));
const ImageList = lazy(() => import("./ImageList"));
const ImageCardList = lazy(() => import("./ImageCardList"));
const NoticeList = lazy(() => import("./NoticeList"));
const Organization = lazy(() => import("./Organization"));
const DetailPage = lazy(() => import("./DetailPage"));
const Login = lazy(() => import("./Login"));
const Guide = lazy(() => import("./Guide"));
const Register = lazy(() => import("./Register"));
const Mine = lazy(() => import('./Mine'))
const UserInfo = lazy(() => import('./UserInfo'))
const Ticket = lazy(() => import('./Ticket'))
const PassWord = lazy(() => import('./PassWord'))
const App = lazy(() => import('./App'))
const Appointment = lazy(() => import('./Appointment'))
const EntryGarden = lazy(() => import('./EntryGarden'))
const MineAppointment = lazy(() => import('./MineAppointment'))
const Complaints = lazy(() => import('./Complaints'))
const Advice = lazy(() => import('./Advice'))
const Find = lazy(() => import('./Find'))
const SysUserInfo = lazy(()=>import('./sysUserinfo'))
const MyOrder = lazy(()=>import('./myOrder'))
const MyPoint = lazy(()=>import('./MyPoint'))
const ForgetPwd = lazy(()=>import('./ForgetPwd'))
export {
    Home,
    Information,
    About,
    TouristNotice,
    ContentPage,
    ContentList,
    ImageList,
    ImageCardList,
    NoticeList,
    Organization,
    DetailPage,
    Login,
    Guide,
    Register,
    Mine,
    UserInfo,
    PassWord,
    App,
    Appointment,
    EntryGarden,
    MineAppointment,
    Complaints,
    Advice,
    Find,
    SysUserInfo,
    MyOrder,
    MyPoint,
    Ticket,
    ForgetPwd,
    Plant

}
