// 登陆状态
enum LOGINSTATE {
    SIGNIN = "LOGINSTATE_SIGNIN",
    SIGNOUT = "LOGINSTATE_SIGNOUT"
}
// dispatch 方法
enum FUNCTOINNAME {
    LOGIN = "FUNCTOINNAME_LOGIN",
    SIGNOUT = "FUNCTOINNAME_SIGNOUT"
}
// 用户权限
enum USERIDENTITY {
    ADMIN = "USERIDENTITY_ADMIN"
}
export {LOGINSTATE,FUNCTOINNAME,USERIDENTITY};